import { MASK_AFTER_CODE_REGEXP } from '@taiga-ui/kit/constants';
function tuiGetMaxAllowedPhoneLength(countries, isoCode) {
  return countries[isoCode].replace(/[()\- ]/g, '').length;
}
function tuiIsoToCountryCode(countriesMasks, isoCode) {
  return countriesMasks[isoCode].replace(MASK_AFTER_CODE_REGEXP, '');
}
function tuiNotKzRegion(value) {
  const region = Number(value.slice(1, 4));
  return region < 600 || region > 799;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiGetMaxAllowedPhoneLength, tuiIsoToCountryCode, tuiNotKzRegion };
