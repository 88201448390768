import { inject, Injectable } from '@angular/core';
import { CompaniesApi, CompanyWithCity } from '@api';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { compareDesc } from 'date-fns';
import { tap } from 'rxjs';
import { CompanyActions } from './companies.actions';
export interface CompaniesStateModel {
  items: Record<string, CompanyWithCity>;
  loading: boolean;
  loaded: boolean;
  selectedId: string | null;
}

const defaults: CompaniesStateModel = {
  items: {},
  loading: false,
  loaded: false,
  selectedId: null,
};
@State<CompaniesStateModel>({ name: 'companies', defaults })
@Injectable()
export class CompaniesState {
  private companiesAPI = inject(CompaniesApi);

  @Selector()
  static state(state: CompaniesStateModel) {
    return state;
  }
  @Selector()
  static isLoaded(state: CompaniesStateModel) {
    return state.loaded;
  }

  @Selector() static selectedCompany(state: CompaniesStateModel) {
    if (!state.selectedId) return null;
    return state.items[state.selectedId];
  }

  @Selector() static selectedCompanyId(state: CompaniesStateModel) {
    return state.selectedId;
  }

  @Selector()
  static loading(state: CompaniesStateModel) {
    return state.loading;
  }

  @Selector()
  static companies(state: CompaniesStateModel) {
    return Object.values(state.items);
  }

  static companyById(idCompany: string) {
    return createSelector([CompaniesState], (state: CompaniesStateModel) => [
      state.items[idCompany],
    ]);
  }

  @Selector()
  static lastAdded(state: CompaniesStateModel) {
    return Object.values(state.items).sort((a, b) =>
      compareDesc(new Date(a.createdAt), new Date(b.createdAt))
    )[0];
  }

  @Action(CompanyActions.Store)
  addJob(ctx: StateContext<CompaniesStateModel>, { createCompanyDto }: CompanyActions.Store) {
    return this.companiesAPI
      .createCompany({ createCompanyDto })
      .pipe(tap(company => ctx.dispatch(new CompanyActions.Add(company))));
  }

  @Action(CompanyActions.Add)
  add(ctx: StateContext<CompaniesStateModel>, { company }: CompanyActions.Add) {
    const { items } = ctx.getState();
    ctx.patchState({ items: { ...items, [company.uid]: company } });
  }

  @Action(CompanyActions.Update)
  async updateJobTriberCompany(
    ctx: StateContext<CompaniesStateModel>,
    { updateCompanyDto, uid }: CompanyActions.Update
  ) {
    ctx.patchState({ loading: true });
    return this.companiesAPI.updateCompany({ updateCompanyDto, uid }).pipe(
      tap(async company => {
        const companies = ctx.getState().items;

        return ctx.patchState({ items: { ...companies, [company.uid]: company }, loading: false });
      })
    );
  }

  @Action(CompanyActions.FetchAll)
  fetchAll(ctx: StateContext<CompaniesStateModel>) {
    if (ctx.getState().loaded) return;

    ctx.patchState({ loading: true });
    return this.companiesAPI.getCurrentUserCompanies().pipe(
      tap(companies => {
        const mapItems = companies.reduce((acc, item) => ({ ...acc, ...{ [item.uid]: item } }), {});
        ctx.patchState({ items: mapItems, loading: false, loaded: true });
      })
    );
  }

  @Action(CompanyActions.Select)
  select(ctx: StateContext<CompaniesStateModel>, { companyId }: CompanyActions.Select) {
    ctx.patchState({ selectedId: companyId });
  }

  @Action(CompanyActions.Reset)
  reset(ctx: StateContext<CompaniesStateModel>) {
    ctx.setState(defaults);
  }
}
