import * as i0 from '@angular/core';
import { Pipe, Inject, NgModule } from '@angular/core';
import { TUI_SVG_OPTIONS } from '@taiga-ui/core/components/svg';
import { TUI_CACHE_BUSTING_PAYLOAD } from '@taiga-ui/core/constants';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
class TuiFlagPipe {
  constructor(svgOptions) {
    this.svgOptions = svgOptions;
    this.staticPath = this.svgOptions.path('tuiIcon').replace('tuiIcon.svg#tuiIcon', '').replace(`tuiIcon.svg${TUI_CACHE_BUSTING_PAYLOAD}#tuiIcon`, '');
  }
  transform(countryIsoCode) {
    if (!countryIsoCode) {
      return null;
    }
    switch (countryIsoCode) {
      case TuiCountryIsoCode.BL:
      case TuiCountryIsoCode.BQ:
      case TuiCountryIsoCode.CW:
      case TuiCountryIsoCode.GF:
      case TuiCountryIsoCode.GP:
      case TuiCountryIsoCode.MF:
      case TuiCountryIsoCode.MQ:
      case TuiCountryIsoCode.NC:
      case TuiCountryIsoCode.RE:
      case TuiCountryIsoCode.YT:
        return `${this.staticPath}${TuiCountryIsoCode.FR}.png`;
      case TuiCountryIsoCode.SX:
        return `${this.staticPath}${TuiCountryIsoCode.NL}.png`;
      default:
        return `${this.staticPath}${countryIsoCode}.png`;
    }
  }
}
TuiFlagPipe.ɵfac = function TuiFlagPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiFlagPipe)(i0.ɵɵdirectiveInject(TUI_SVG_OPTIONS, 16));
};
TuiFlagPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiFlag",
  type: TuiFlagPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFlagPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiFlag'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_SVG_OPTIONS]
      }]
    }];
  }, null);
})();
class TuiFlagPipeModule {}
TuiFlagPipeModule.ɵfac = function TuiFlagPipeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiFlagPipeModule)();
};
TuiFlagPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFlagPipeModule
});
TuiFlagPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFlagPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFlagPipe],
      exports: [TuiFlagPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFlagPipe, TuiFlagPipeModule };
