import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { EventEmitter, ChangeDetectorRef, TemplateRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, Output, ContentChild, HostBinding, Directive, NgModule } from '@angular/core';
import * as i5 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { maskitoTransform, MASKITO_DEFAULT_OPTIONS } from '@maskito/core';
import { maskitoPrefixPostprocessorGenerator, maskitoCaretGuard } from '@maskito/kit';
import * as i2 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, AbstractTuiControl, tuiIsNativeFocused, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure, TuiActiveZoneModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_MASK_SYMBOLS_REGEXP, TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_SIZE, tuiAsDataListHost, TuiDataListDirective, TuiHostedDropdownComponent, TuiPrimitiveTextfieldComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule, TuiTextfieldControllerModule, TuiTextfieldComponent } from '@taiga-ui/core';
import { FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/kit/providers';
import * as i3 from '@taiga-ui/kit/directives';
import { TuiValueAccessorModule } from '@taiga-ui/kit/directives';
import * as i4 from '@maskito/angular';
import { MaskitoModule } from '@maskito/angular';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
const TUI_INPUT_PHONE_DEFAULT_OPTIONS = {
  phoneMaskAfterCountryCode: '### ###-##-##',
  allowText: false,
  countryCode: '+7'
};
/**
 * Default parameters for input phone component
 */
const TUI_INPUT_PHONE_OPTIONS = tuiCreateToken(TUI_INPUT_PHONE_DEFAULT_OPTIONS);
function tuiInputPhoneOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_PHONE_OPTIONS, options, TUI_INPUT_PHONE_DEFAULT_OPTIONS);
}
const countDigits = value => value.replace(/\D/g, '').length;
/**
 * `InputPhone` component sets country code as non-removable prefix.
 * This prefix appears on focus and cannot be erased.
 * But users sometimes can copy complete phone number (from any different source)
 * and try to insert the whole string inside our `InputPhone` textfield.
 * This preprocessor helps to avoid country prefix duplication on paste/drop/autofill events.
 */
function tuiCreateCompletePhoneInsertionPreprocessor(countryCode, phoneMaskAfterCountryCode) {
  const completePhoneLength = (countryCode + phoneMaskAfterCountryCode).replace(/[^#\d]+/g, '').length;
  const trimCountryPrefix = value => countryCode === '+7' ? value.replace(/^(\+?7?\s?8?)\s?/, '') : value.replace(new RegExp(`^(\\+?${countryCode.replace('+', '')}?)\\s?`), '');
  return ({
    elementState,
    data
  }) => {
    const {
      value,
      selection
    } = elementState;
    return {
      elementState: {
        selection,
        value:
        /**
         * The only possible case when `value` includes digits more
         * than mask expression allows – browser autofill.
         * It means that we are inside `input`-event
         * and mask are ready to reject "extra" characters.
         * We should cut leading country prefix to save trailing characters!
         */
        countDigits(value) > completePhoneLength ? trimCountryPrefix(value) : value
      },
      data: countDigits(data) >= completePhoneLength ?
      /**
       * User tries to insert/drop the complete phone number (with country prefix).
       * We should drop already existing non-removable prefix.
       */
      trimCountryPrefix(data) : data
    };
  };
}

/**
 * Create {@link https://maskito.dev/core-concepts/mask-expression pattern mask expression} for phone number
 *
 * @example
 * tuiCreatePhoneMaskExpression('+1', '(###) ###-####');
 */
function tuiCreatePhoneMaskExpression(countryCode, phoneMaskAfterCountryCode) {
  return [...countryCode.split(''), ' ', ...phoneMaskAfterCountryCode.replace(/[^#\- ()]+/g, '').split('').map(item => item === '#' ? /\d/ : item)];
}
function isText(value) {
  return Number.isNaN(parseInt(value.replace(TUI_MASK_SYMBOLS_REGEXP, ''), 10));
}
class TuiInputPhoneComponent extends AbstractTuiControl {
  constructor(control, cdr, textfieldCleaner, options, textfieldSize) {
    super(control, cdr);
    this.textfieldCleaner = textfieldCleaner;
    this.options = options;
    this.textfieldSize = textfieldSize;
    this.phoneMaskAfterCountryCode = this.options.phoneMaskAfterCountryCode;
    this.allowText = this.options.allowText;
    this.search = '';
    this.searchChange = new EventEmitter();
    this.countryCode = this.options.countryCode;
    this.open = false;
  }
  set countryCodeSetter(newCountryCode) {
    const prevCountryCode = this.countryCode;
    this.countryCode = newCountryCode;
    this.updateValueWithNewCountryCode(prevCountryCode, newCountryCode);
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    return !this.textfield || this.computedDisabled ? null : this.textfield.nativeFocusableElement;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement) || !!this.dropdown && this.dropdown.focused;
  }
  get nativeValue() {
    var _a;
    return ((_a = this.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.value) || maskitoTransform(this.value, this.maskOptions);
  }
  set nativeValue(value) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.value = value;
    }
  }
  get inputMode() {
    return this.allowText ? 'text' : 'numeric';
  }
  get canOpen() {
    return this.interactive && !!this.datalist;
  }
  get canClean() {
    return this.nativeValue !== this.nonRemovablePrefix && this.textfieldCleaner.cleaner;
  }
  get maskOptions() {
    return this.calculateMask(this.countryCode, this.phoneMaskAfterCountryCode, this.nonRemovablePrefix, this.allowText);
  }
  onActiveZone(active) {
    this.updateFocused(active);
    if (active && !this.nativeValue && !this.readOnly && !this.allowText) {
      this.updateSearch(this.nonRemovablePrefix);
      this.nativeValue = this.nonRemovablePrefix;
      return;
    }
    if (this.nativeValue === this.nonRemovablePrefix || this.isTextValue) {
      this.updateSearch('');
      this.nativeValue = '';
      return;
    }
    if (!active && !this.allowText && this.nativeFocusableElement) {
      this.nativeValue = this.nativeValue.replace(/\D$/, '');
    }
  }
  onValueChange(value) {
    const parsed = isText(value) ? value : value.replace(TUI_MASK_SYMBOLS_REGEXP, '').slice(0, this.maxPhoneLength);
    this.updateSearch(parsed);
    this.value = parsed === this.countryCode || isText(parsed) ? '' : parsed;
    this.open = true;
    if (!this.value && !this.allowText) {
      this.nativeValue = this.nonRemovablePrefix;
    }
  }
  handleOption(item) {
    this.focusInput();
    this.value = item;
    this.nativeValue = maskitoTransform(this.value, this.maskOptions);
    this.updateSearch('');
    this.open = false;
  }
  setDisabledState() {
    super.setDisabledState();
    this.open = false;
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = maskitoTransform(value || '', this.maskOptions);
    this.updateSearch('');
  }
  getFallbackValue() {
    return '';
  }
  get nonRemovablePrefix() {
    return `${this.countryCode} `;
  }
  get maxPhoneLength() {
    return this.countryCode.length + this.phoneMaskAfterCountryCode.replace(/[^#]+/g, '').length;
  }
  get isTextValue() {
    return !!this.search && isText(this.search);
  }
  calculateMask(countryCode, phoneMaskAfterCountryCode, nonRemovablePrefix, allowText) {
    const mask = tuiCreatePhoneMaskExpression(countryCode, phoneMaskAfterCountryCode);
    const preprocessors = [tuiCreateCompletePhoneInsertionPreprocessor(countryCode, phoneMaskAfterCountryCode)];
    return allowText ? {
      mask: ({
        value
      }) => isText(value) && value !== '+' ? MASKITO_DEFAULT_OPTIONS.mask : mask,
      preprocessors
    } : {
      mask,
      preprocessors,
      postprocessors: [maskitoPrefixPostprocessorGenerator(nonRemovablePrefix)],
      plugins: [maskitoCaretGuard((value, [from, to]) => [from === to ? nonRemovablePrefix.length : 0, value.length])]
    };
  }
  focusInput() {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll: true
      });
    }
  }
  updateSearch(search) {
    if (this.search === search) {
      return;
    }
    this.search = search;
    this.searchChange.emit(search);
  }
  updateValueWithNewCountryCode(prevCountryCode, newCountryCode) {
    if (!this.isTextValue) {
      this.value = this.value.replace(prevCountryCode, newCountryCode);
      this.nativeValue = maskitoTransform(this.value, this.maskOptions);
    }
  }
}
TuiInputPhoneComponent.ɵfac = function TuiInputPhoneComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiInputPhoneComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_CLEANER), i0.ɵɵdirectiveInject(TUI_INPUT_PHONE_OPTIONS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE));
};
TuiInputPhoneComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputPhoneComponent,
  selectors: [["tui-input-phone"]],
  contentQueries: function TuiInputPhoneComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
    }
  },
  viewQuery: function TuiInputPhoneComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiHostedDropdownComponent, 5);
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdown = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputPhoneComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    countryCodeSetter: [0, "countryCode", "countryCodeSetter"],
    phoneMaskAfterCountryCode: "phoneMaskAfterCountryCode",
    allowText: "allowText",
    search: "search"
  },
  outputs: {
    searchChange: "searchChange"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputPhoneComponent), tuiAsControl(TuiInputPhoneComponent), tuiAsDataListHost(TuiInputPhoneComponent)], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 4,
  vars: 13,
  consts: [[1, "t-hosted", 3, "openChange", "tuiActiveZoneChange", "canOpen", "content", "open"], ["tuiValueAccessor", "", 1, "t-textfield", 3, "valueChange", "disabled", "focusable", "invalid", "maskito", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldCleaner", "value"]],
  template: function TuiInputPhoneComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 0);
      i0.ɵɵtwoWayListener("openChange", function TuiInputPhoneComponent_Template_tui_hosted_dropdown_openChange_0_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.open, $event) || (ctx.open = $event);
        return $event;
      });
      i0.ɵɵlistener("tuiActiveZoneChange", function TuiInputPhoneComponent_Template_tui_hosted_dropdown_tuiActiveZoneChange_0_listener($event) {
        return ctx.onActiveZone($event);
      });
      i0.ɵɵelementStart(1, "tui-primitive-textfield", 1);
      i0.ɵɵtwoWayListener("valueChange", function TuiInputPhoneComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.nativeValue, $event) || (ctx.nativeValue = $event);
        return $event;
      });
      i0.ɵɵlistener("valueChange", function TuiInputPhoneComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
        return ctx.onValueChange($event);
      });
      i0.ɵɵprojection(2);
      i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("canOpen", ctx.canOpen)("content", ctx.datalist || "");
      i0.ɵɵtwoWayProperty("open", ctx.open);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.focusable)("invalid", ctx.computedInvalid)("maskito", ctx.maskOptions)("nativeId", ctx.nativeId)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldCleaner", ctx.canClean);
      i0.ɵɵtwoWayProperty("value", ctx.nativeValue);
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiPrimitiveTextfieldComponent, i2.TuiActiveZoneDirective, i1.TuiPrimitiveTextfieldDirective, i3.TuiValueAccessorDirective, i4.MaskitoDirective, i1.TuiTextfieldCleanerDirective],
  styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}._disabled[_nghost-%COMP%]{pointer-events:none}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputPhoneComponent.prototype, "calculateMask", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-phone',
      templateUrl: './input-phone.template.html',
      styleUrls: ['./input-phone.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputPhoneComponent), tuiAsControl(TuiInputPhoneComponent), tuiAsDataListHost(TuiInputPhoneComponent)],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i5.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i1.TuiTextfieldCleanerDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_CLEANER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_PHONE_OPTIONS]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }];
  }, {
    dropdown: [{
      type: ViewChild,
      args: [TuiHostedDropdownComponent]
    }],
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    countryCodeSetter: [{
      type: Input,
      args: ['countryCode']
    }],
    phoneMaskAfterCountryCode: [{
      type: Input
    }],
    allowText: [{
      type: Input
    }],
    search: [{
      type: Input
    }],
    searchChange: [{
      type: Output
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    calculateMask: []
  });
})();
class TuiInputPhoneDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.nativeValue;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  process(input) {
    this.input = input;
  }
  ngDoCheck() {
    if (!this.input) {
      return;
    }
    this.input.type = 'tel';
    this.input.inputMode = this.host.inputMode;
  }
}
TuiInputPhoneDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputPhoneDirective_BaseFactory;
  return function TuiInputPhoneDirective_Factory(__ngFactoryType__) {
    return (ɵTuiInputPhoneDirective_BaseFactory || (ɵTuiInputPhoneDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputPhoneDirective)))(__ngFactoryType__ || TuiInputPhoneDirective);
  };
})();
TuiInputPhoneDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputPhoneDirective,
  selectors: [["tui-input-phone"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputPhoneDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-phone',
      providers: [tuiAsTextfieldHost(TuiInputPhoneDirective)]
    }]
  }], null, null);
})();
class TuiInputPhoneModule {}
TuiInputPhoneModule.ɵfac = function TuiInputPhoneModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiInputPhoneModule)();
};
TuiInputPhoneModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputPhoneModule
});
TuiInputPhoneModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[MaskitoModule, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule, TuiTextfieldControllerModule, TuiActiveZoneModule, TuiValueAccessorModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneModule, [{
    type: NgModule,
    args: [{
      imports: [MaskitoModule, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule, TuiTextfieldControllerModule, TuiActiveZoneModule, TuiValueAccessorModule],
      declarations: [TuiInputPhoneComponent, TuiInputPhoneDirective],
      exports: [TuiInputPhoneComponent, TuiInputPhoneDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_PHONE_DEFAULT_OPTIONS, TUI_INPUT_PHONE_OPTIONS, TuiInputPhoneComponent, TuiInputPhoneDirective, TuiInputPhoneModule, tuiInputPhoneOptionsProvider };
