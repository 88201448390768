import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { EventEmitter, ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, Output, HostBinding, HostListener, NgModule } from '@angular/core';
import * as i5 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import * as i4 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, tuiGetClipboardDataText, AbstractTuiControl, CHAR_PLUS, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure, TuiLetModule, TuiMapperPipeModule, TuiActiveZoneModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_NON_DIGITS_REGEXP, TUI_MASK_SYMBOLS_REGEXP, TuiFlagPipe, TUI_TEXTFIELD_SIZE, TuiPrimitiveTextfieldComponent, TuiSvgModule, TuiGroupModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiHintModule, TuiHostedDropdownModule, TuiDataListModule, TuiWrapperModule, TuiFlagPipeModule } from '@taiga-ui/core';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { TUI_ARROW, TuiArrowModule } from '@taiga-ui/kit/components/arrow';
import * as i2 from '@taiga-ui/kit/components/input-phone';
import { TuiInputPhoneComponent, TuiInputPhoneModule } from '@taiga-ui/kit/components/input-phone';
import * as i8 from '@taiga-ui/kit/pipes';
import { TuiToCountryCodePipe } from '@taiga-ui/kit/pipes';
import { FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/kit/providers';
import { TUI_COUNTRIES, TUI_COUNTRIES_MASKS } from '@taiga-ui/kit/tokens';
import { tuiIsoToCountryCode, tuiGetMaxAllowedPhoneLength } from '@taiga-ui/kit/utils';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i6 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i7 from 'rxjs';
const _c0 = ["*"];
function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_6_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_6_button_1_Template_button_click_0_listener() {
      const item_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.onItemClick(item_r4));
    });
    i0.ɵɵelement(1, "img", 11);
    i0.ɵɵpipe(2, "tuiFlag");
    i0.ɵɵelementStart(3, "span", 12);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "span", 13);
    i0.ɵɵtext(6);
    i0.ɵɵpipe(7, "tuiMapper");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    const countriesNames_r5 = i0.ɵɵnextContext(2).ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("src", i0.ɵɵpipeBind1(2, 3, item_r4), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", countriesNames_r5[item_r4], " ");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(7, 5, item_r4, ctx_r1.isoToCountryCodeMapper), " ");
  }
}
function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tui-data-list");
    i0.ɵɵtemplate(1, TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_6_button_1_Template, 8, 8, "button", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.countries);
  }
}
function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 14);
    i0.ɵɵpipe(1, "tuiFlag");
  }
  if (rf & 2) {
    const countriesNames_r5 = i0.ɵɵnextContext().ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("alt", countriesNames_r5[ctx_r1.countryIsoCode])("src", i0.ɵɵpipeBind1(1, 2, ctx_r1.countryIsoCode), i0.ɵɵsanitizeUrl);
  }
}
function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_10_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtemplate(1, TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_10_ng_container_1_Template, 1, 0, "ng-container", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.arrow);
  }
}
function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-hosted-dropdown", 4);
    i0.ɵɵtwoWayListener("openChange", function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_Template_tui_hosted_dropdown_openChange_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r1.open, $event) || (ctx_r1.open = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵlistener("tuiActiveZoneChange", function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_Template_tui_hosted_dropdown_tuiActiveZoneChange_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onActiveZone($event));
    });
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelement(2, "tui-primitive-textfield", 6);
    i0.ɵɵelementStart(3, "tui-input-phone", 7);
    i0.ɵɵtwoWayListener("ngModelChange", function TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_Template_tui_input_phone_ngModelChange_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r1.value, $event) || (ctx_r1.value = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵprojection(4);
    i0.ɵɵelement(5, "input", 8);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(6, TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_6_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(8, TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_8_Template, 2, 4, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(10, TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_ng_template_10_Template, 2, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dropdown_r6 = i0.ɵɵreference(7);
    const countryValueContent_r7 = i0.ɵɵreference(9);
    const icon_r8 = i0.ɵɵreference(11);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("canOpen", !ctx_r1.readOnly)("content", dropdown_r6);
    i0.ɵɵtwoWayProperty("open", ctx_r1.open);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("disabled", ctx_r1.disabled)("editable", false)("focusable", ctx_r1.focusable)("pseudoFocus", ctx_r1.open || null)("readOnly", ctx_r1.readOnly)("tuiTextfieldCustomContent", countryValueContent_r7)("tuiTextfieldIcon", icon_r8)("tuiTextfieldLabelOutside", true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("countryCode", ctx_r1.inputPhoneCountryCode)("disabled", ctx_r1.disabled)("focusable", ctx_r1.focusable)("phoneMaskAfterCountryCode", ctx_r1.phoneMaskAfterCountryCode)("pseudoFocus", ctx_r1.pseudoFocus)("pseudoHover", ctx_r1.pseudoHover)("pseudoInvalid", ctx_r1.computedInvalid)("readOnly", ctx_r1.readOnly);
    i0.ɵɵtwoWayProperty("ngModel", ctx_r1.value);
  }
}
const TUI_INPUT_PHONE_INTERNATIONAL_DEFAULT_OPTIONS = {
  countries: [],
  countryIsoCode: TuiCountryIsoCode.RU
};
/**
 * Default parameters for input phone international component
 */
const TUI_INPUT_PHONE_INTERNATIONAL_OPTIONS = tuiCreateToken(TUI_INPUT_PHONE_INTERNATIONAL_DEFAULT_OPTIONS);
function tuiInputPhoneInternationalOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_PHONE_INTERNATIONAL_OPTIONS, options, TUI_INPUT_PHONE_INTERNATIONAL_DEFAULT_OPTIONS);
}
function tuiExtractValueFromEvent(event) {
  var _a;
  return 'dataTransfer' in event ? ((_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.getData('text/plain')) || '' : tuiGetClipboardDataText(event);
}
class TuiInputPhoneInternationalComponent extends AbstractTuiControl {
  constructor(control, cdr, countriesNames$, countriesMasks, options, flagPipe, extractCountryCodePipe, textfieldSize) {
    super(control, cdr);
    this.countriesNames$ = countriesNames$;
    this.countriesMasks = countriesMasks;
    this.options = options;
    this.flagPipe = flagPipe;
    this.extractCountryCodePipe = extractCountryCodePipe;
    this.textfieldSize = textfieldSize;
    this.countries = this.options.countries;
    this.countryIsoCodeChange = new EventEmitter();
    this.countryIsoCode = this.options.countryIsoCode;
    this.open = false;
    this.arrow = TUI_ARROW;
    this.isoToCountryCodeMapper = item => tuiIsoToCountryCode(this.countriesMasks, item);
  }
  set isoCode(code) {
    var _a;
    if (this.countryIsoCode === code) {
      return;
    }
    (_a = this.inputPhoneComponent) === null || _a === void 0 ? void 0 : _a.writeValue(this.value);
    this.countryIsoCode = code;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    return this.inputPhoneComponent && !this.computedDisabled ? this.inputPhoneComponent.nativeFocusableElement : null;
  }
  get focused() {
    return !!this.primitiveTextfield && this.primitiveTextfield.focused || !!this.inputPhoneComponent && this.inputPhoneComponent.focused;
  }
  get inputPhoneCountryCode() {
    return tuiIsoToCountryCode(this.countriesMasks, this.countryIsoCode);
  }
  get phoneMaskAfterCountryCode() {
    const countryCode = this.inputPhoneCountryCode;
    return this.calculateMaskAfterCountryCode(this.countriesMasks[this.countryIsoCode], countryCode);
  }
  /**
   * @deprecated use `<img [src]="countryIsoCode | tuiFlagPipe" />`
   * TODO drop in v4.0
   */
  get countryFlagPath() {
    return this.getFlagPath(this.countryIsoCode);
  }
  onPaste(event) {
    let value = tuiExtractValueFromEvent(event).replace(TUI_NON_DIGITS_REGEXP, '');
    const countryIsoCode = this.extractCountryCodePipe.transform(value, this.countries);
    if (!countryIsoCode) {
      this.value = `${this.inputPhoneCountryCode}${value}`.replace(TUI_MASK_SYMBOLS_REGEXP, '').slice(0, tuiGetMaxAllowedPhoneLength(this.countriesMasks, this.countryIsoCode));
      return;
    }
    if (countryIsoCode === TuiCountryIsoCode.RU) {
      value = value.replace(/^8/, '7');
    }
    this.updateCountryIsoCode(countryIsoCode);
    this.value = `${CHAR_PLUS}${value}`;
  }
  /**
   * @deprecated use `<img [src]="countryIsoCode | tuiFlagPipe" />`
   * TODO drop in v4.0
   */
  getFlagPath(code) {
    return this.flagPipe.transform(code);
  }
  onItemClick(isoCode) {
    this.open = false;
    this.updateCountryIsoCode(isoCode);
    // recalculates mask inside inputPhone to prevent isoCode conflict
    this.cdr.detectChanges();
    const maxLength = tuiGetMaxAllowedPhoneLength(this.countriesMasks, isoCode);
    if (this.value.length > maxLength) {
      this.value = this.value.slice(0, maxLength);
    }
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus();
    }
  }
  setDisabledState() {
    super.setDisabledState();
    this.close();
  }
  /**
   * @deprecated use `{{ countryIsoCode | tuiIsoToCountryCode }}`
   * TODO drop in v4.0
   */
  isoToCountryCode(isoCode) {
    return tuiIsoToCountryCode(this.countriesMasks, isoCode);
  }
  /** @deprecated use 'value' setter */
  onModelChange(value) {
    this.value = value;
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  getFallbackValue() {
    return '';
  }
  calculateMaskAfterCountryCode(mask, countryCode) {
    return mask.replace(countryCode, '').trim();
  }
  close() {
    this.open = false;
  }
  updateCountryIsoCode(code) {
    this.countryIsoCode = code;
    this.countryIsoCodeChange.emit(code);
  }
}
TuiInputPhoneInternationalComponent.ɵfac = function TuiInputPhoneInternationalComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiInputPhoneInternationalComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_COUNTRIES), i0.ɵɵdirectiveInject(TUI_COUNTRIES_MASKS), i0.ɵɵdirectiveInject(TUI_INPUT_PHONE_INTERNATIONAL_OPTIONS), i0.ɵɵdirectiveInject(TuiFlagPipe), i0.ɵɵdirectiveInject(TuiToCountryCodePipe), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE));
};
TuiInputPhoneInternationalComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputPhoneInternationalComponent,
  selectors: [["tui-input-phone-international"]],
  viewQuery: function TuiInputPhoneInternationalComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiInputPhoneComponent, 5);
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputPhoneComponent = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.primitiveTextfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputPhoneInternationalComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("paste.capture.prevent.stop", function TuiInputPhoneInternationalComponent_paste_capture_prevent_stop_HostBindingHandler($event) {
        return ctx.onPaste($event);
      })("drop.capture.prevent.stop", function TuiInputPhoneInternationalComponent_drop_capture_prevent_stop_HostBindingHandler($event) {
        return ctx.onPaste($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    isoCode: [0, "countryIsoCode", "isoCode"],
    countries: "countries"
  },
  outputs: {
    countryIsoCodeChange: "countryIsoCodeChange"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputPhoneInternationalComponent), tuiAsControl(TuiInputPhoneInternationalComponent),
  // TODO: for backward compatibility only. Drop in v4.0
  TuiFlagPipe, TuiToCountryCodePipe], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 2,
  vars: 3,
  consts: [["dropdown", ""], ["countryValueContent", ""], ["icon", ""], ["class", "t-hosted-dropdown", 3, "canOpen", "content", "open", "openChange", "tuiActiveZoneChange", 4, "ngIf"], [1, "t-hosted-dropdown", 3, "openChange", "tuiActiveZoneChange", "canOpen", "content", "open"], ["tuiGroup", ""], ["tuiHintContent", "", "tuiTextfieldPostfix", "", "tuiTextfieldPrefix", "", 1, "t-country-select", "tui-group__auto-width-item", 3, "disabled", "editable", "focusable", "pseudoFocus", "readOnly", "tuiTextfieldCustomContent", "tuiTextfieldIcon", "tuiTextfieldLabelOutside"], [1, "t-input-phone", "tui-group__auto-width-item", 3, "ngModelChange", "countryCode", "disabled", "focusable", "phoneMaskAfterCountryCode", "pseudoFocus", "pseudoHover", "pseudoInvalid", "readOnly", "ngModel"], ["autocomplete", "new-password", "tuiTextfield", ""], ["tuiOption", "", 3, "click", 4, "ngFor", "ngForOf"], ["tuiOption", "", 3, "click"], ["alt", "", 1, "t-country-item-flag", 3, "src"], [1, "t-country-item-name"], [1, "t-country-item-code"], [1, "t-flag", 3, "alt", "src"], ["appearance", "icon", "tuiWrapper", ""], [4, "polymorpheusOutlet"]],
  template: function TuiInputPhoneInternationalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, TuiInputPhoneInternationalComponent_tui_hosted_dropdown_0_Template, 12, 20, "tui-hosted-dropdown", 3);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.countriesNames$));
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiPrimitiveTextfieldComponent, i2.TuiInputPhoneComponent, i1.TuiTextfieldComponent, i1.TuiDataListComponent, i1.TuiOptionComponent, i3.NgIf, i4.TuiActiveZoneDirective, i1.TuiGroupDirective, i1.TuiPrimitiveTextfieldDirective, i1.TuiHintOptionsDirective, i1.TuiTextfieldPostfixDirective, i1.TuiTextfieldPrefixDirective, i1.TuiTextfieldCustomContentDirective, i1.TuiTextfieldIconDirective, i1.TuiTextfieldLabelOutsideDirective, i2.TuiInputPhoneDirective, i5.NgControlStatus, i5.NgModel, i3.NgForOf, i1.TuiWrapperDirective, i6.PolymorpheusOutletDirective, i3.AsyncPipe, i1.TuiFlagPipe, i4.TuiMapperPipe],
  styles: ["[_nghost-%COMP%]{display:block}._disabled[_nghost-%COMP%]{pointer-events:none}.t-hosted-dropdown[_ngcontent-%COMP%]{display:block}.t-country-select[_ngcontent-%COMP%]{width:5.625rem}.t-country-select[_ngcontent-%COMP%]:not(._readonly)     input:not(:disabled){cursor:pointer}.t-country-select._readonly[_ngcontent-%COMP%]     input{cursor:default}.t-country-select[data-size=m][_ngcontent-%COMP%]{width:5.5rem}.t-country-select[data-size=s][_ngcontent-%COMP%]{width:2rem}.t-country-select[data-size=s][_ngcontent-%COMP%]   .t-flag[_ngcontent-%COMP%]{margin-left:-1rem}.t-arrow-icon[_ngcontent-%COMP%]{position:relative;display:flex;width:1.5rem;height:1.5rem;align-items:center;justify-content:center;box-sizing:border-box;cursor:pointer;pointer-events:none;pointer-events:auto}._readonly[_nghost-%COMP%]   .t-arrow-icon[_ngcontent-%COMP%], ._disabled[_nghost-%COMP%]   .t-arrow-icon[_ngcontent-%COMP%]{pointer-events:none}.t-arrow-icon_open[_ngcontent-%COMP%]{transform:rotate(180deg)}.t-input-phone[_ngcontent-%COMP%]{flex:1}.t-flag[_ngcontent-%COMP%]{width:1.75rem;height:1.25rem;margin-left:-.5rem}.t-country-item-flag[_ngcontent-%COMP%]{width:1.75rem;height:1.25rem}.t-country-item-name[_ngcontent-%COMP%]{margin-left:.75rem;margin-right:auto}.t-country-item-code[_ngcontent-%COMP%]{color:var(--tui-text-02);margin-right:.25rem}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputPhoneInternationalComponent.prototype, "calculateMaskAfterCountryCode", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneInternationalComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-phone-international',
      templateUrl: './input-phone-international.template.html',
      styleUrls: ['./input-phone-international.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputPhoneInternationalComponent), tuiAsControl(TuiInputPhoneInternationalComponent),
      // TODO: for backward compatibility only. Drop in v4.0
      TuiFlagPipe, TuiToCountryCodePipe],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i5.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i7.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_COUNTRIES]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_COUNTRIES_MASKS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_PHONE_INTERNATIONAL_OPTIONS]
      }]
    }, {
      type: i1.TuiFlagPipe,
      decorators: [{
        type: Inject,
        args: [TuiFlagPipe]
      }]
    }, {
      type: i8.TuiToCountryCodePipe,
      decorators: [{
        type: Inject,
        args: [TuiToCountryCodePipe]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }];
  }, {
    inputPhoneComponent: [{
      type: ViewChild,
      args: [TuiInputPhoneComponent]
    }],
    primitiveTextfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    isoCode: [{
      type: Input,
      args: ['countryIsoCode']
    }],
    countries: [{
      type: Input
    }],
    countryIsoCodeChange: [{
      type: Output
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    onPaste: [{
      type: HostListener,
      args: ['paste.capture.prevent.stop', ['$event']]
    }, {
      type: HostListener,
      args: ['drop.capture.prevent.stop', ['$event']]
    }],
    calculateMaskAfterCountryCode: []
  });
})();
class TuiInputPhoneInternationalModule {}
TuiInputPhoneInternationalModule.ɵfac = function TuiInputPhoneInternationalModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiInputPhoneInternationalModule)();
};
TuiInputPhoneInternationalModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputPhoneInternationalModule
});
TuiInputPhoneInternationalModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, PolymorpheusModule, TuiSvgModule, TuiInputPhoneModule, TuiGroupModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiHintModule, TuiHostedDropdownModule, TuiDataListModule, TuiArrowModule, TuiWrapperModule, TuiLetModule, TuiMapperPipeModule, TuiActiveZoneModule, TuiFlagPipeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneInternationalModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, PolymorpheusModule, TuiSvgModule, TuiInputPhoneModule, TuiGroupModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiHintModule, TuiHostedDropdownModule, TuiDataListModule, TuiArrowModule, TuiWrapperModule, TuiLetModule, TuiMapperPipeModule, TuiActiveZoneModule, TuiFlagPipeModule],
      declarations: [TuiInputPhoneInternationalComponent],
      exports: [TuiInputPhoneInternationalComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_PHONE_INTERNATIONAL_DEFAULT_OPTIONS, TUI_INPUT_PHONE_INTERNATIONAL_OPTIONS, TuiInputPhoneInternationalComponent, TuiInputPhoneInternationalModule, tuiExtractValueFromEvent, tuiInputPhoneInternationalOptionsProvider };
