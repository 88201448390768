import * as i0 from '@angular/core';
import { Pipe, Inject, NgModule } from '@angular/core';
import { CHAR_PLUS } from '@taiga-ui/cdk';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { TUI_COUNTRIES_MASKS } from '@taiga-ui/kit/tokens';
import { tuiGetMaxAllowedPhoneLength, tuiIsoToCountryCode, tuiNotKzRegion } from '@taiga-ui/kit/utils';
class TuiToCountryCodePipe {
  constructor(countriesMasks) {
    this.countriesMasks = countriesMasks;
  }
  transform(value, countries) {
    return countries.find(countryIsoCode => {
      const ruCodeTest = countryIsoCode === TuiCountryIsoCode.RU && /^[78]/.test(value) && /^(?!880[1-9 ])/.test(value) && value.length + 1 === tuiGetMaxAllowedPhoneLength(this.countriesMasks, TuiCountryIsoCode.RU);
      const matched = ruCodeTest || value.startsWith(tuiIsoToCountryCode(this.countriesMasks, countryIsoCode).replace(CHAR_PLUS, '')) && value.length + 1 === tuiGetMaxAllowedPhoneLength(this.countriesMasks, countryIsoCode);
      if (matched) {
        switch (countryIsoCode) {
          case TuiCountryIsoCode.RU:
            return tuiNotKzRegion(value);
          case TuiCountryIsoCode.KZ:
            return !tuiNotKzRegion(value);
          default:
            return true;
        }
      }
      return false;
    });
  }
}
TuiToCountryCodePipe.ɵfac = function TuiToCountryCodePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiToCountryCodePipe)(i0.ɵɵdirectiveInject(TUI_COUNTRIES_MASKS, 16));
};
TuiToCountryCodePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiToCountryCode",
  type: TuiToCountryCodePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiToCountryCodePipe, [{
    type: Pipe,
    args: [{
      name: 'tuiToCountryCode'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_COUNTRIES_MASKS]
      }]
    }];
  }, null);
})();
class TuiExtractCountryCodeModule {}
TuiExtractCountryCodeModule.ɵfac = function TuiExtractCountryCodeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiExtractCountryCodeModule)();
};
TuiExtractCountryCodeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiExtractCountryCodeModule
});
TuiExtractCountryCodeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiExtractCountryCodeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiToCountryCodePipe],
      exports: [TuiToCountryCodePipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiExtractCountryCodeModule, TuiToCountryCodePipe };
